/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    li: "li",
    a: "a",
    section: "section",
    div: "div",
    h2: "h2",
    p: "p",
    ul: "ul"
  }, _provideComponents(), props.components), {TableOfContents, CallToAction, Partner} = _components;
  if (!CallToAction) _missingMdxReference("CallToAction", true);
  if (!Partner) _missingMdxReference("Partner", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, null, React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#who-are-we-"
  }, "WHO ARE WE?")), React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#the-pact-consortium"
  }, "THE PACT CONSORTIUM")), React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#partners"
  }, "Partners")), React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#supporters"
  }, "Supporters"))), React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-light uk-overflow-hidden  ",
    id: "who-are-we-"
  }, React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-1"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-3"
  }), React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.h2, null, "Who are we?"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "The PACT methodology is being developed at the University of Cambridge by the ", React.createElement("a", {
    href: "https://4c.cst.cam.ac.uk/",
    target: "_blank"
  }, "Cambridge Centre for Carbon Credits (4C)"), ", an interdisciplinary collaboration between the Departments of Computer Science, Plant Science and Zoology.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "The team’s first goal is to scale up the supply of deforestation avoidance carbon credits in order to halt tropical deforestation as soon as possible.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "With a team of senior scientists, research associates and postgraduate students, the centre is deepening our understanding of forest lifecycles, biodiversity, remote sensing technology, and computer science tools like artificial intelligence and machine learning. This allows us to develop robust new methodologies for assessing the impact of carbon credit which put nature-based solutions at the forefront.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "We hope to work with organisations throughout the carbon market – developers of nature-based projects, buyers of carbon credits, and those who work in the wider carbon credits ecosystem – in a range of ways.")))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-section-cta uk-background-dark uk-position-relative"
  }, React.createElement(CallToAction)), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-light uk-overflow-hidden  ",
    id: "the-pact-consortium"
  }, React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-1"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-3"
  }), React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.h2, null, "The PACT Consortium"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "We are building a consortium of academics, NGOs and some commercial companies who commit to set up, scrutinise and use credible methodologies to assess carbon credits through digital MRV in order to:")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Raise the position of nature based solutions"), "\n", React.createElement(_components.li, null, "Reduce cost, time and risk for projects"), "\n", React.createElement(_components.li, null, "Bring scale to address climate and biodiversity crises"), "\n", React.createElement(_components.li, null, "Advance ethical practice and transparency across the value chain"), "\n"))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: "partners"
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.h2, null, "Partners"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "We are also looking for organisations which are aligned with our vision for transparency in the carbon credit market to partner with us without the commitment of consortium membership.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "These partnerships could take many forms: we are interested to hear from organisations in the carbon credits ecosystem who might be interested to use or contribute to the development of PACT methodologies, including infrastructure providers, nature-based projects, credit buyers, NGOs and multilateral organisations.")))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-light uk-overflow-hidden  ",
    id: "supporters"
  }, React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-1"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-3"
  }), React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.h2, null, "Supporters"), React.createElement("div", {
    className: "uk-grid uk-margin-top"
  }, React.createElement(Partner, {
    name: "Tezos",
    icon: "tezos-dark",
    url: "https://tezos.com/"
  }, React.createElement(_components.p, null, "Tezos is an energy-efficient blockchain where developers, entrepreneurs and others from around the world build and engage with a network of decentralized applications. Using the Tezos ecosystem allows us to build a trusted, decentralised platform to track the measurement, reporting and verification (MRV) of carbon credits issued using the PACT methodology.")), React.createElement(Partner, {
    name: "Geotree",
    icon: "geotree-dark",
    url: "https://geotree.com/"
  }, React.createElement(_components.p, null, "Geotree provides scalable institutional grade monitoring, reporting, and verification (MRV) technology to support nature-based carbon projects. Their deep earth observation technology allows MRV of nature based carbon projects in agriculture, forestry and coastal wetlands."))))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
